import { Controller } from "@hotwired/stimulus"
import 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@3.0.1/dist/cookieconsent.umd.js';
import { post, patch } from "@rails/request.js";

// Connects to data-controller="cookie-consent"
export default class extends Controller {
  connect() {
    CookieConsent.run({
      onFirstConsent: async ({cookie}) => {
        await submitConsentData(cookie);
        location.reload();
      },
      onChange: async ({cookie}) => {
        await updateConsentData(cookie);
        location.reload();
      },
      cookie: {
        name: 'cc_cookie',
        expiresAfterDays: 365
      },
      disablePageInteraction: true,
      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        analytics: {}
      },
      guiOptions: {
        consentModal: {
          layout: 'box',
          position: 'middle center',
          equalWeightButtons: true,
          flipButtons: false
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: "It's your choice",
              description: 'We use necessary cookies to ensure the proper functioning of our website. Additionally, we use analytics cookies to help us improve your browsing experience by collecting data on how you use our site.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage preferences'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              sections: [
                {
                  title: 'Strictly Necessary cookies',
                  description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Performance and Analytics',
                  description: 'These cookies collect information about how you use our website.',
                  linkedCategory: 'analytics'
                },
              ]
            }
          }
        }
      }
    });
  }
}

async function submitConsentData(cookie) {
  const data = { body: { cookie_consent: { consent_id: cookie.consentId, analytics_enabled: cookie.categories.includes("analytics") }}, contentType: "application/json"};

  await post('/cookie_consent', data);
}

async function updateConsentData(cookie) {
  const data = { body: { cookie_consent: { analytics_enabled: cookie.categories.includes("analytics") }}, contentType: "application/json"};

  await patch(`/cookie_consent/${cookie.consentId}`, data);
}
