import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="drawer"
export default class extends Controller {
    static targets = ["arrow", "menu", "aside"];
    connect() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    toggleDrawerState() {
        this.arrowTarget.classList.toggle("rotate-180");
        this.menuTarget.classList.toggle("drawer-menu-extended");
        this.menuTarget.classList.toggle("drawer-menu-collapsed");

        const menuItems = this.menuTarget.querySelectorAll('li span')
        menuItems.forEach(function(item) {
            item.classList.toggle('opacity-0');
        })

        const drawerState = this.isDrawerCollapsed() ? "collapsed" : "extended";
        document.cookie = `drawer_state=${drawerState}; path=/; SameSite=Lax`;
    }

    handleResize() {
        const mediumScreenWidth = 768;
        if (window.innerWidth < mediumScreenWidth) {
            this.enableModalDrawerButton();

            if(this.isDrawerCollapsed()) {
                this.toggleDrawerState();
            }
        } else {
            this.disableModalDrawerButton();
        }
    }

    isDrawerCollapsed() {
        return this.menuTarget.classList.contains('drawer-menu-collapsed')
    }

    enableModalDrawerButton() {
        this.asideTarget.classList.add("drawer-side");
    }
    
    disableModalDrawerButton() {
        this.asideTarget.classList.remove("drawer-side");
    }
}
